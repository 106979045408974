@for $size from 10 through 96 {
  .fs--#{$size} {
    font-size: $size/16 + rem !important;
  }
}
$weights: 100 200 300 400 500 600 700 800;
@each $weight in $weights {
  .fw--#{$weight} {
    font-weight: $weight !important;
  }
}

.gradient-border-mask::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  border: 2px solid transparent;
  background: linear-gradient(45deg, #c03dfd, #4f81fd) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.gradient-mask {
  position: relative;
}
.bgImage {
  background-image: url('../images/topRight.png') right top no-repeat,
    url('../images/round.png') left bottom repeat;
}
.gradient-mask::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-right-radius: 10px;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  background: linear-gradient(90deg, #c03dfd, #4f81fd) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
// sidebar menu active
.sideBarMenuGradient {
  background: transparent;
}
.sideBarMenuGradientActive {
  background: transparent linear-gradient(97deg, #c03dfd 0%, #4f81fd 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 20px 0px 0px 20px;
}

.glass-effect {
  /* From https://css.glass */
  // background: rgba(0, 0, 0, 1);
  // border-radius: 16px;
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(13.4px);
  // -webkit-backdrop-filter: blur(13.4px);
  // border: 1px solid rgba(0, 0, 0, 0.31);
  /* From https://css.glass */

  // background: rgba(25, 24, 24, 0.85);
  // border-radius: 16px;
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(13.8px);
  // -webkit-backdrop-filter: blur(13.8px);
  // border: 1px solid rgba(25, 24, 24, 1);

  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(16.5px);
  -webkit-backdrop-filter: blur(16.5px);
  border: 1px solid rgba(0, 0, 0, 1);
}
.rotate-icon {
  transform: rotate(180deg);
}
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Fallback for non-WebKit browsers */
  display: block;
  display: -webkit-box;
  max-height: 2.4em;
  line-height: 1.2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// scrollbar
/* For webkit browsers */
::-webkit-scrollbar {
  width: 0px;
}
