@font-face {
  font-family: 'Gotham';
  src: url('./assets/font/Gotham/Gotham-Light.otf');
  font-style: normal;
}
body {
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: initial;
  }
}
